import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import util from './utils/util';
import './utils/lib-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.prototype.util = util;

Vue.config.productionTip = false
Vue.use(ElementUI).use(Vant);
// Vue.use(Vant);
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
